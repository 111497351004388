import Vue from 'vue'
import Vuetify from 'vuetify'

Vue.use(Vuetify)

export default new Vuetify({
	theme: {
		themes: {
			light: {
				primary: '#006064',
				secondary: '#e2f1f8'
			},
			dark: {
				primary: '#ba000d',
				secondary: '#707070'
			}
		}
	}
})
