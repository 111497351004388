import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: localStorage.getItem("token") || "",
    version: "ver 3.4.179",
    snackme: false,
    tiempo: 5000,
    estado: "success",
    mensaje: "xxx",
    titulofix: "???",
  },
  mutations: {
    autor_borra() {
      localStorage.clear();
    },
    autor_ok(state, variable) {
      state.token = variable;
    },
  },
  actions: {
    login({ commit }, parametro) {
      commit("autor_borra");
      return new Promise((resolve) => {
        axios.post("/login", parametro).then((response) => {
          if (!response.data) {
            resolve(false);
          } else {
            commit("autor_ok", response.data);
            localStorage.setItem("token", response.data);
            resolve(true);
          }
        });
      });
    },
    logout({ commit }) {
      commit("autor_borra");
    },
  },
});
