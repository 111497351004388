import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import syssound from './plugins/sounds'
import axios from 'axios'


Vue.config.productionTip = false
Vue.use(syssound)
axios.defaults.baseURL = '//api.stevenpanel.com'

new Vue({
	router,
	store,
	vuetify,
	render: h => h(App)
}).$mount('#app')
