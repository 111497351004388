export default {
	install (Vue) {
		Vue.prototype.$warningsnd = () => {
			let warnsnd = new Audio(require('@/assets/snd/warning.ogg'))
			warnsnd.play()
		}
		Vue.prototype.$successsnd = () => {
			let exitosnd = new Audio(require('@/assets/snd/success.ogg'))
			exitosnd.play()
		}
		Vue.prototype.$errorsnd = () => {
			let errorsnd = new Audio(require('@/assets/snd/error.ogg'))
			errorsnd.play()
		}
	}
}