<template>
	<v-app>
		<v-navigation-drawer 
			class="blue-grey darken-4" 
			:src="require('@/assets/bg7.jpg')" 
			dark 
			:permanent="stPermanent"
			:stateless="!stPermanent"
			:key="stMenuExp" 
			:expand-on-hover="stMenuExp"
			app
		>
			<v-list-item id="menuse" class="stevenbg">
				<v-list-item-avatar size="25">
					<img src="@/assets/se-home.png" />
				</v-list-item-avatar>
				<v-list-item-content>
					<v-list-item-title class="title">
						<div class="text-titulo">STEVEN EVEN</div>
					</v-list-item-title>
				</v-list-item-content>
				<v-list-item-icon>
					<v-icon small @click="fnMenuToggle">{{ pinflag }}</v-icon>
				</v-list-item-icon>
			</v-list-item>

			<v-divider></v-divider>

			<v-list-item v-for="item in steven" :key="item.link" :to="item.link" dark class="stevenbg">
				<v-list-item-action>
					<v-icon>{{ item.icon }}</v-icon>
				</v-list-item-action>
				<v-list-item-content>
					<v-list-item-title class="white--text">{{ item.titulo }}</v-list-item-title>
				</v-list-item-content>
			</v-list-item>

			<v-divider></v-divider>
			
			<v-list-item id="menumus" class="musbg">
				<v-list-item-avatar size="25">
					<img src="@/assets/mus-home.png" />
				</v-list-item-avatar>
				<v-list-item-content>
					<v-list-item-title class="title">
						<div class="text-titulo">MUS</div>
					</v-list-item-title>
				</v-list-item-content>
			</v-list-item>

			<v-divider></v-divider>

			<v-list-item v-for="item in mus" :key="item.link" :to="item.link" dark class="musbg">
				<v-list-item-action>
					<v-icon>{{ item.icon }}</v-icon>
				</v-list-item-action>
				<v-list-item-content>
					<v-list-item-title class="white--text">{{ item.titulo }}</v-list-item-title>
				</v-list-item-content>
			</v-list-item>

			<v-divider></v-divider>

			<v-list-item id="menumus" class="xtrabg">
				<v-list-item-avatar size="25">
					<img src="@/assets/miselaneo.png" />
				</v-list-item-avatar>
				<v-list-item-content>
					<v-list-item-title class="title">
						<div class="text-titulo">MISELANEOUS</div>
					</v-list-item-title>
				</v-list-item-content>
			</v-list-item>

			<v-list-item v-for="item in misel" :key="item.link" :to="item.link" dark class="xtrabg">
				<v-list-item-action>
					<v-icon>{{ item.icon }}</v-icon>
				</v-list-item-action>
				<v-list-item-content>
					<v-list-item-title class="white--text">{{ item.titulo }}</v-list-item-title>
				</v-list-item-content>
			</v-list-item>

		</v-navigation-drawer>

		<v-app-bar color="blue-grey darken-4" dark dense flat app v-if="stPermanent">
			<v-toolbar-title class="text-titulo puntero" @click="$router.push('/')">Shopify Manager Panel</v-toolbar-title>
			<v-chip class="ma-2 deep-orange darken-2" small>{{ $store.state.version }}</v-chip>
			<div>{{ titulo }}</div>
			<v-spacer></v-spacer>
			<v-chip label class="ma-2 blue-grey darken-4 lime--text text-uppercase font-weight-bold">
				<v-icon left class="lime--text">mdi-menu-right</v-icon>{{ $store.state.titulofix }}<v-icon right class="lime--text">mdi-menu-left</v-icon>
			</v-chip>
			<v-spacer></v-spacer>
			<v-btn icon @click="fnGotop" color="yellow darken-4"><v-icon>mdi-arrow-up-bold-circle-outline</v-icon></v-btn>
			<v-btn icon @click="fnLogout"><v-icon>mdi-exit-to-app</v-icon></v-btn>
		</v-app-bar>
		
		<v-main>
			<v-container fluid>
				<router-view></router-view>
			</v-container>
		</v-main>

		<v-snackbar
			v-model="$store.state.snackme"
			:timeout="$store.state.tiempo"
			:color="$store.state.estado"
			top
			right>
			{{ $store.state.mensaje }}
			<template v-slot:action="{ attrs }">
				<v-btn
					color="grey darken-3"
					v-bind="attrs"
					text
					@click="$store.state.snackme = false"
				>
					Close
				</v-btn>
			</template>
		</v-snackbar>

	</v-app>
</template>

<script>
export default {
	name: 'App',
	metaInfo: {
		title: 'Panel',
		titleTemplate: '%s :: Shopify Manager Panel'
	},
	data() {
		return {
			mus: [
				{link: '/mus-orders', icon: 'mdi-cart', titulo: 'Orders fron MUS'},
				{link: '/mus-provider', icon: 'mdi-truck', titulo: 'Send to VPM'},
				{link: '/mus-products', icon: 'mdi-tshirt-crew', titulo: 'Products'},
				{link: '/mus-newsletter', icon: 'mdi-newspaper', titulo: 'Newsletter'},
				{link: '/mus-discount', icon: 'mdi-timetable', titulo: 'Discount'}
			],
			steven: [
				{link: '/se-orders', icon: 'mdi-cart', titulo: 'Orders from Steven'},
				{link: '/se-provider', icon: 'mdi-truck', titulo: 'Send to VPM'},
				{link: '/se-products', icon: 'mdi-tshirt-crew', titulo: 'Products'},
				{link: '/se-newsletter', icon: 'mdi-newspaper', titulo: 'Newsletter'},
				{link: '/se-discount', icon: 'mdi-timetable', titulo: 'Discount'}
			],
			misel: [
				{link: '/feedlist', icon: 'mdi-view-grid', titulo: 'VPM Feed'},
				{link: '/subscription', icon: 'mdi-email', titulo: 'Subscription'},
			],
			stPermanent: false,
			pinflag: 'mdi-radiobox-marked',
			stMenuExp: false,
			titulo: this.$refs.detailsChild
		}
	},
	methods: {
		fnMenuToggle() {
			if(this.pinflag == 'mdi-radiobox-marked') {
				this.pinflag = 'mdi-radiobox-blank'
			}else{
				this.pinflag = 'mdi-radiobox-marked'
			}
			this.stMenuExp = !this.stMenuExp
		},
		fnLogout() {
			this.$store.dispatch('logout')
			window.location.href = "/login"
		},
		fnGotop() {
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth'
			})
		}
	},
	mounted() {
		if(this.$store.state.token !== '') {
			this.stPermanent = true
			this.stMenuExp = false
		}
	}
}
</script>

<style>
.text-titulo {
	font-family: 'Fira Sans Extra Condensed', sans-serif !important;
	text-transform: uppercase;
}
.puntero {
	cursor: pointer;
}
.section-title {
	color: #455A64;
	display:inline-block;
	height: 30px;
	box-shadow: 0 15px 0 -12px #90A4AE;
}
thead tr, .v-list-item__content {
	/*text-transform: uppercase;*/
}
.theme--light .v-data-table-header > tr > th {
	background-color: #424242 !important;
	color: white !important;
	text-transform: uppercase;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
	text-transform: uppercase;
}
.theme--dark.v-list-item--active:hover::before, .theme--dark.v-list-item--active::before {
	background-color: #FFFFFF;
	border-bottom: #ECEFF1 thin dotted;
	border-top: #ECEFF1 thin dotted;
}
.v-data-table {
	border: solid thin #CFD8DC;
}
.stevenbg {
	background-color: rgba(230, 81, 0, 0.80);
}
.musbg {
	background-color: rgba(2, 119, 189, 0.80);
}
.xtrabg {
	background-color: rgba(96, 125, 139, 0.80);
}
</style>