import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index.js'
import Meta from 'vue-meta'

Vue.use(VueRouter)
Vue.use(Meta)

const routes = [
	{
		path: '/',
		name: 'home',
		component: () => import('../views/home.vue'),
		meta: { requiresAuth: true }
	},
	{
		path: '/mus-orders',
		name: 'mus-orders',
		component: () => import('../views/mus-orders.vue'),
		meta: { requiresAuth: true }
	},
	{
		path: '/mus-provider',
		name: 'mus-provider',
		component: () => import('../views/mus-provider.vue'),
		meta: { requiresAuth: true }
	},
	{
		path: '/mus-products',
		name: 'mus-products',
		component: () => import('../views/mus-products.vue'),
		meta: { requiresAuth: true }
	},
	{
		path: '/mus-newsletter',
		name: 'mus-newsletter',
		component: () => import('../views/mus-newsletter.vue'),
		meta: { requiresAuth: true }
	},
	{
		path: '/mus-discount',
		name: 'mus-discount',
		component: () => import('../views/mus-discount.vue'),
		meta: { requiresAuth: true }
	},
/* Steven */
	{
		path: '/se-orders',
		name: 'se-orders',
		component: () => import('../views/se-orders.vue'),
		meta: { requiresAuth: true }
	},
	{
		path: '/se-provider',
		name: 'se-provider',
		component: () => import('../views/se-provider.vue'),
		meta: { requiresAuth: true }
	},
	{
		path: '/se-products',
		name: 'se-products',
		component: () => import('../views/se-products.vue'),
		meta: { requiresAuth: true }
	},
	{
		path: '/se-newsletter',
		name: 'se-newsletter',
		component: () => import('../views/se-newsletter.vue'),
		meta: { requiresAuth: true }
	},
	{
		path: '/se-discount',
		name: 'se-discount',
		component: () => import('../views/se-discount.vue'),
		meta: { requiresAuth: true }
	},
/* Miselaneos */
	{
		path: '/feedlist',
		name: 'feed',
		component: () => import('../views/feedlist.vue'),
		meta: { requiresAuth: true }
	},
	{
		path: '/subscription',
		name: 'subscription',
		component: () => import('../views/subscription.vue'),
		meta: { requiresAuth: true }
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('../views/login.vue')
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

router.beforeEach((to, from, next) => {
	if(to.matched.some(record => record.meta.requiresAuth)) {
		if (store.state.token) {
			next()
			return
		}
		next('/login') 
	} else {
		next()
	}
})

export default router